/** @jsx jsx */
import {jsx} from '@emotion/react';
import {FC, Fragment, useCallback, useEffect, useRef, useState} from 'react';

import {observer} from 'mobx-react-lite';
import {destroy, onAction} from 'mobx-state-tree';
import {isFunction, isNull, isNumber, isUndefined} from 'lodash-es';

import {createStore, IStore} from '../../IStore';
import {createDisposers} from '../../utils/disposers';
import {fetchPoster} from '../../fetchPreview';
import {cancelable, ICancelablePromise} from '../../utils/cancelablePromise';

import {useGetActiveStream, useHideControls, useMountOverlay, useApiRepository, useCameraLockedData} from '../../hooks';
import {Stream, StreamStatuses} from '../../hooks/useSubStream';

import {
  PlayingGroup,
  Spinner,
  ControlPanel,
  IAvailableControl,
  CameraBlocked,
  LowSpeedPreview,
  NoPreview,
  NoStreams,
  StreamError,
  Timestamp
} from '../../components';
import {initPlyr} from '../../components/initPlyr';
import {ArrowBack} from '../../components/control-panel/controls';

import {
  defaultVideoStyles,
  gradientCss,
  overlayCss,
  panelPositionCss,
  relativeStyle,
  visibleCss
} from '../../components/common';

import {MountHooks, OverlayParams} from '../../IWidgetProps';

export interface IPreviewProps {
  cameraId: string;
  ratio: 'fit' | string | null | undefined;
  overlayMountHooks?: MountHooks<OverlayParams>;
  previewOnly: boolean;
  onPlay?: () => void;
  autoUpdate?: number;
  stopAutoUpdate?: () => void;
}

const revokeUrls = (video: HTMLVideoElement) => {
  const propName = video.src ? 'src' : 'poster';
  window.URL.revokeObjectURL(video[propName]);
  video[propName] = '';
};

export const Preview: FC<IPreviewProps> = observer(
  ({cameraId, autoUpdate, stopAutoUpdate, ratio, previewOnly = false, overlayMountHooks, onPlay}) => {
    const {api} = useApiRepository();
    const {activeStream, fetchStreams} = useGetActiveStream();
    const [store, setStore] = useState<IStore | null>(null);
    const {getter: lockingInfo} = useCameraLockedData();
    const {isLoading: lockingInfoLoading, canI, is} = lockingInfo;

    const [controls, setControls] = useState<IAvailableControl[]>(['blockCamera']);

    const containerRef = useRef<HTMLDivElement | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const lastBlockedStreamStatus = useRef(false);

    const externalOverlayRef = useMountOverlay(videoRef, store, overlayMountHooks);
    const controlsVisible = useHideControls(containerRef);

    const loadPreview: (store: IStore, activeStream: Stream) => ICancelablePromise<void> = useCallback(
      (store: IStore, activeStream: Stream) => {
        const abortController = new AbortController();
        const getStreamUrl = async () => {
          try {
            // @ts-ignore
            const headers = await api.getHeaders();
            const previewBlob = await fetchPoster(headers, activeStream.snapshotStreamUrl, abortController.signal);

            if (abortController.signal.aborted) return;
            if (!previewBlob) {
              store.setIsNoPreview(true);
              return;
            }

            store.setIsNoPreview(false);
            videoRef.current && revokeUrls(videoRef.current);

            const url = window.URL.createObjectURL(previewBlob);
            const isVideoPoster = previewBlob.type.startsWith('video');

            if (store.isBlockedStream) return;
            videoRef.current && (videoRef.current[isVideoPoster ? 'src' : 'poster'] = url);
          } catch (e) {
            console.error(e);
          }
        };

        return cancelable(() => {
          abortController.abort();
        }, getStreamUrl());
      },
      [api]
    );

    useEffect(() => {
      const store = createStore();
      store.setIsWaiting(true);
      setStore(store);
      return () => destroy(store);
    }, [cameraId]);

    useEffect(() => {
      if (lockingInfoLoading || !store || !activeStream) return;
      const streamBlockStatus = is.streamLockedNow && !canI.watchLockedStreams;
      const controlBlockStatus = is.controlsLockedNow && !is.streamLockedNow;

      store.setIsBlockedStream(streamBlockStatus);
      store.setIsBlockedControls(controlBlockStatus);

      if (lastBlockedStreamStatus.current && !streamBlockStatus) fetchStreams();
      if (streamBlockStatus && videoRef.current) revokeUrls(videoRef.current);

      lastBlockedStreamStatus.current = streamBlockStatus;
    }, [store, canI, is, lockingInfoLoading, activeStream, fetchStreams]);

    useEffect(() => {
      const video = videoRef.current;
      const container = containerRef.current;
      if (!store || !video || !container) return;
      const actualControls: IAvailableControl[] = ['play', 'fullscreen', 'streams', 'screenshot', 'blockCamera'];
      const d = createDisposers();

      const controls: IAvailableControl[] = previewOnly ? [] : actualControls;

      const plyr = initPlyr(video, container, ratio, store, controls);
      d.add(() => plyr.destroy());
      const canPlay = !previewOnly && isFunction(onPlay);

      canPlay &&
        d.add(
          onAction(store, (action) => {
            const {name, args} = action;
            if (name !== 'setIsPlaying') return;
            const [value, source] = args as [boolean, string];
            if (source !== 'user' || !value) return;
            onPlay();
          })
        );

      plyr.on('ready', () => setControls(controls));
      return d.flush;
    }, [onPlay, previewOnly, ratio, store]);

    useEffect(() => {
      const video = videoRef.current;
      if (!store || !video) return;
      if (isUndefined(activeStream)) return;

      if (isNull(activeStream)) {
        store.setIsUnavailable(true);
        store.setIsWaiting(false);
        return;
      }

      if (activeStream.status === StreamStatuses.error) {
        revokeUrls(video);
        store.setIsWaiting(false);
        store.setIsNoPreview(false);
        return;
      }

      const d = createDisposers();
      d.add(revokeUrls.bind(null, video));
      d.add(loadPreview(store, activeStream).cancel);
      store.setIsWaiting(false);
      if (isNumber(autoUpdate) && autoUpdate > 0)
        d.add(createInterval(() => loadPreview(store, activeStream), autoUpdate));

      return d.flush;
    }, [activeStream, autoUpdate, loadPreview, store]);

    const lowSpeedMode = isNumber(autoUpdate) && autoUpdate > 0;
    const hasControls = controls.length > 0;
    const hasControlPanel = hasControls && !store?.isUnavailable && !lowSpeedMode;

    return (
      <div ref={containerRef} style={relativeStyle} className="main-container">
        <div>
          {store && <Timestamp store={store} isPreview={true} />}
          <video css={defaultVideoStyles} ref={videoRef} />
        </div>
        {!isNull(store) && (
          <Fragment>
            {controlsVisible && store.isFullscreen && (
              <ArrowBack onClick={() => store?.setIsFullscreen(false, 'user')} />
            )}
            <PlayingGroup store={store} />
            {hasControls && <div data-visible={controlsVisible || lowSpeedMode} css={[gradientCss, visibleCss]} />}
            <div css={overlayCss}>
              <PreviewScreensFactory
                onStop={stopAutoUpdate}
                onPlay={onPlay}
                isLowSpeed={lowSpeedMode}
                stream={activeStream}
                store={store}
              />
              <Spinner store={store} />
            </div>
            <div ref={externalOverlayRef} css={overlayCss} />
            {hasControlPanel && (
              <ControlPanel
                store={store}
                controls={controls}
                videoRef={videoRef}
                data-visible={controlsVisible || lowSpeedMode}
                css={[panelPositionCss, visibleCss]}
              />
            )}
          </Fragment>
        )}
      </div>
    );
  }
);

const PreviewScreensFactory: FC<{
  store: IStore;
  stream?: Stream | null;
  isLowSpeed: boolean;
  onPlay?: () => void;
  onStop?: () => void;
}> = observer(({store, stream, isLowSpeed, onPlay, onStop}) => {
  if (stream?.status === StreamStatuses.error) return <StreamError />;
  if (isLowSpeed) return <LowSpeedPreview onRestore={onPlay} onStop={onStop} />;
  if (store.isBlockedStream) return <CameraBlocked />;
  if (store.isUnavailable) return <NoStreams />;
  if (store.isNoPreview) return <NoPreview />;
  return null;
});

function createInterval(func: () => ICancelablePromise<void>, ms: number) {
  let id: any = 0;
  let promise: void | ICancelablePromise<void>;
  let promiseSettled = true;

  const cancelInterval = () => {
    if (!id) return;
    clearInterval(id);
    id = 0;
    if (promise instanceof Promise) promise.cancel();
  };

  id = setInterval(() => {
    if (!promiseSettled) return;
    promise = func();
    promiseSettled = false;
    promise.finally(() => (promiseSettled = true));
  }, ms);

  return cancelInterval;
}
