import React, {useCallback, useState} from 'react';
import {WidgetReceivedProps} from '@netvision/lib-widget-renderer';
import {Live, Preview} from './modules';
import {StreamProvider} from './hooks';
import {IWidgetProps} from './IWidgetProps';

import {PresetsProvider} from './components/ptzControls/hooks/usePresets';
import {MovingProvider} from './components/ptzControls/hooks/useMoving';
import {ToastProvider} from './components/ptzControls/hooks/useToastRef';

import {
  LockInfoProvider,
  LibApiRepoProvider,
  LocaleProvider,
  WidgetPropsProvider,
  CameraTimeOffsetProvider
} from './providers';
import {defaults} from 'lodash-es';

export default function Root(props: {
  props: IWidgetProps;
  domElement?: HTMLElement;
  mountChildren: WidgetReceivedProps<IWidgetProps, ''>['mountChildren'];
}) {
  const widgetProps = defaults(props.props, {
    mountChildren: props.mountChildren,
    previewInterval: 0,
    stalledTimeout: 10000,
    stalledPreviewInterval: 6000,
    previewOnly: false,
    ptzRequiresFullscreen: true,
    initiallyShowPtz: false,
    lockFeatureSettings: {},
    refreshAfterUnlockTime: 3000,
    maxStreamRefreshAttempts: 2
  });

  let {
    camera,
    cameraId: _cameraId,
    ratio,
    overlayMountHooks,
    previewInterval,
    stalledTimeout,
    stalledPreviewInterval,
    previewOnly = false,
    streamType,
    autoplay,
    disablePtz,
    defaultStreamType,
    defaultStreamId,
    lib,
    getStreamSetterByType,
    refreshAfterUnlockTime,
    maxStreamRefreshAttempts
  } = widgetProps;

  const DEFAULT_LIB_API = {
    name: 'lib-api',
    mixins: ['cubejs']
  };

  const [playerState, setPlayerState] = useState<'preview' | 'live'>(autoplay ? 'live' : 'preview');
  const [slideShow, setSlideShow] = useState(false);

  const cameraId = (camera?.id || _cameraId || '').toString();

  const setLive = useCallback(() => setPlayerState('live'), []);
  const setPreview = useCallback(() => setPlayerState('preview'), []);
  const stopAutoUpdate = useCallback(() => setSlideShow(false), []);

  const onStalled = useCallback(() => {
    setSlideShow(true);
    setPreview();
  }, [setPreview]);

  return (
    <WidgetPropsProvider.Provider {...widgetProps}>
      <LibApiRepoProvider.Provider lib={lib || DEFAULT_LIB_API}>
        <StreamProvider
          eventNode={props.domElement}
          streamSetter={getStreamSetterByType}
          cameraId={cameraId}
          refreshAfterUnlockTime={refreshAfterUnlockTime}
          maxStreamRefreshAttempts={maxStreamRefreshAttempts}
          defaultStreamId={defaultStreamId}
          defaultStreamType={defaultStreamType}>
          <ToastProvider position={'bottom-center'}>
            <LocaleProvider.Provider>
              <LockInfoProvider.Provider>
                <CameraTimeOffsetProvider.Provider timezoneSettings={camera?.timezoneSettings}>
                  {playerState === 'preview' && (
                    <Preview
                      onPlay={setLive}
                      previewOnly={previewOnly}
                      autoUpdate={slideShow ? stalledPreviewInterval : previewInterval}
                      ratio={ratio}
                      cameraId={cameraId}
                      overlayMountHooks={overlayMountHooks}
                      stopAutoUpdate={stopAutoUpdate}
                    />
                  )}
                  {playerState === 'live' && (
                    <MovingProvider>
                      <PresetsProvider cameraId={cameraId}>
                        <Live
                          onStalled={onStalled}
                          onStop={setPreview}
                          ratio={ratio}
                          cameraId={cameraId}
                          stalledTimeout={stalledTimeout}
                          disablePtz={!!disablePtz}
                          streamType={streamType}
                        />
                      </PresetsProvider>
                    </MovingProvider>
                  )}
                </CameraTimeOffsetProvider.Provider>
              </LockInfoProvider.Provider>
            </LocaleProvider.Provider>
          </ToastProvider>
        </StreamProvider>
      </LibApiRepoProvider.Provider>
    </WidgetPropsProvider.Provider>
  );
}
