export const E2E_ATTRIBUTES = {
    video: 'video-element',
    noStream: 'video-no-stream',
    noPreview: 'video-no-preview',
    blocked: 'video-blocked',
    lowSpeedPriview: 'video-low-speed',
    error: 'video-error',
    volumeSlider: 'slider-volume',
    mutedUnMutedBtn: 'button-volume',
    controlPanel: 'player-control-panel',
    playPauseBtn: 'player-live-preview-button',
    exitFullScreen: 'button-fullscreen-exit',
    enterFullScreen: 'button-fullscreen-enter',
    screenshot: 'button-screenshot',
    stream: 'button-stream',
    buttonHq: 'button-hq',
}